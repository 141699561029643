import { http, param } from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.OPEN_FDA_BASE_URL}`

const fdaServices = {
    getAllFdaRecalls(payload) {
        var arr = [
            { query: 'page_number', val: payload.pageNumber },
            { query: 'page_size', val: payload.pageSize },
            { query: 'sort_field', val: payload.sortField },
            { query: 'sort_direction', val: payload.sortDirection },
            { query: 'search_query', val: payload.search ? payload.search.trim() : '' },
            { query: 'filter_model', val: payload.filterModel ? encodeURIComponent(JSON.stringify(payload.filterModel)) : null },
            { query: 'recall_type', val: payload.recall_type ? payload.recall_type : ''}
        ]
        return http.get(`${API_ROOT}/getrecalls${param(arr)}`).then(resp => {
            return resp
        })
    },
    exportAllFdaRecalls(payload) {
        var query_params = param([
            { query: 'sort_field', val: payload.sortField },
            { query: 'sort_direction', val: payload.sortDirection },
            { query: 'search_query', val: payload.search_query ? payload.search_query.trim() : '' },
            { query: 'filter_model', val: payload.filter_model ? encodeURIComponent(JSON.stringify(payload.filter_model)) : null },
            { query: 'email', val: payload.email ? payload.email : '' },
            { query: 'recall_type', val: payload.recall_type ? payload.recall_type : ''}
        ])
        return http.get(`${API_ROOT}/exportRecalls${query_params}`).then(resp => {
            return resp
        })
    },
    getFdaRecall(objectId, recall_type) {
        return http.get(`${API_ROOT}/getrecall?objectId=${objectId}&recall_type=${recall_type}`).then(resp => {
            return resp
        })
    },
    getFdaRecallMetaData(recall_type) {
        return http.get(`${API_ROOT}/getmeta?recall_type=${recall_type}`).then(resp => {
            return resp
        })
    }
}

export default fdaServices